import { defineVerdureAppCreator } from "@verdure/core";
import PluginsConsole from "@verdure/plugins-console";
import ModuleCoreWeb, { customSysUser } from "@verdure/core-web";
import ModuleMonitoring from "@verdure/system-monitoring";
import ModuleWechatPay from "@verdure/system-wechat-pay";
import ModuleCodeCreate from "@verdure/expand-code-create";
import ModuleSwagger from "@verdure/swagger-api-doc";

// import '@verdure/core/lib/index.css'
// import '@verdure/core-web/lib/index.css'
// import '@verdure/system-monitoring/lib/index.css'
// import '@verdure/system-wechat-pay/lib/index.css'
// import '@verdure/plugins-console/lib/index.css'
// import '@verdure/expand-code-create/lib/index.css'
// import '@verdure/swagger-api-doc/lib/index.css'

import "./styles/index.scss";

import router from "./router";

import slip from "./module";

import ExpandUserForm from "./slot/custom-slot/user/ExpandUserForm.vue";
import ExpandUserTable from "./slot/custom-slot/user/ExpandUserTable.vue";
import ExpandUserQuery from "./slot/custom-slot/user/ExpandUserQuery.vue";

window.document.title = (import.meta as any).env.VITE_APP_SYSTEM_NAME;

defineVerdureAppCreator({
  router,
  config: {
    core: {
      systemName: (import.meta as any).env.VITE_APP_SYSTEM_NAME,
      request: {
        // baseUrl: 'http://127.0.0.1:40003/', //lhb本地
        // baseUrl: 'http://192.168.0.8:40003/', //zcy本地
        // baseUrl: 'http://192.168.0.162:40003/', //pxr本地
        baseUrl: "https://www.sidajingang.com/admin/api/",//正式环境
        // baseUrl: "https://fq-test.sidajingang.com/admin/api/",// 测试环境
        // baseUrl: 'http://192.168.0.22/admin/api/',// 开发环境
        // baseUrl: "/admin/api/",
        publicKey:
          "MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBAJu/gmcsUfyBHh78ryXZ6IPsNY4Fxh4YWzIH6ezDyG5XKhhOpLOEfAXf7SpvKf2njmIBVpLD0cuHXn2sN26TU+0CAwEAAQ==",
      },
    },
  },
  modules: [
    slip,
    ModuleCoreWeb,
    ModuleMonitoring,
    ModuleWechatPay,
    ModuleCodeCreate,
    ModuleSwagger,
  ],
  plugins: [PluginsConsole],
});

// 自定义系统用户功能
customSysUser({
  formAfterEndSlot: ExpandUserForm,
  tableAfterEndSlot: ExpandUserTable,
  queryAfterEndSlot: ExpandUserQuery,
});
